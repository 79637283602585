import * as React from "react"
import { base } from "../styles/ColorStyles"

export default function BlockWrapper({ children, color }) {
  return (
    <div style={Styles} color={color}>
      {children}
    </div>
  )
}

const Styles = {
  background: `${base.lightgray}`,
  padding: `2rem 1.5rem 1rem 1.5rem`,
  borderRadius: `6px`,
  margin: `2.5rem 0`,
}
