import Seo from "../../../../src/components/seo";
import VideoPlayer from "../../../../src/components/post/video";
import Info from "../../../../src/components/post/info";
import EmbedVideo from "../../../../src/components/post/embedvideo";
import Video0 from "../../../../src/images/work/atlasai/aperture/video0.mp4";
import Video1 from "../../../../src/images/work/atlasai/aperture/video1.mp4";
import Video2 from "../../../../src/images/work/atlasai/aperture/video2.mp4";
import Video3 from "../../../../src/images/work/atlasai/aperture/video3.mp4";
import Video4 from "../../../../src/images/work/atlasai/aperture/video4.mp4";
import Video5 from "../../../../src/images/work/atlasai/aperture/video5.mp4";
import Video6 from "../../../../src/images/work/atlasai/aperture/video6.mp4";
import * as React from 'react';
export default {
  Seo,
  VideoPlayer,
  Info,
  EmbedVideo,
  Video0,
  Video1,
  Video2,
  Video3,
  Video4,
  Video5,
  Video6,
  React
};