import * as React from "react"
import styled from "styled-components"

export default function VideoPlayer(props) {
  function createOAuthString() {
    return (
      "https://www.youtube.com/embed" +
      `/${props.linkHash}` +
      "?autoplay=1&playlist" +
      `=${props.linkHash}` +
      "&loop=1&mute=1&controls=0"
    )
  }

  const videourl = createOAuthString()
  return (
    <>
      <EmbedWrapper>
        <iframe
          width="100%"
          height="100%"
          src={videourl}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </EmbedWrapper>
    </>
  )
}

const EmbedWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 1rem auto;
  max-width: 840px;
  border-radius: 4px;
  :after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
