// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gridtest-js": () => import("./../../../src/pages/gridtest.js" /* webpackChunkName: "component---src-pages-gridtest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-new-york-mdx": () => import("./../../../src/pages/misc/new-york.mdx" /* webpackChunkName: "component---src-pages-misc-new-york-mdx" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-work-aperture-research-mdx": () => import("./../../../src/pages/work/aperture-research.mdx" /* webpackChunkName: "component---src-pages-work-aperture-research-mdx" */),
  "component---src-pages-work-demand-intel-mdx": () => import("./../../../src/pages/work/demand-intel.mdx" /* webpackChunkName: "component---src-pages-work-demand-intel-mdx" */),
  "component---src-pages-work-kayak-mdx": () => import("./../../../src/pages/work/kayak.mdx" /* webpackChunkName: "component---src-pages-work-kayak-mdx" */),
  "component---src-pages-work-playcook-mdx": () => import("./../../../src/pages/work/playcook.mdx" /* webpackChunkName: "component---src-pages-work-playcook-mdx" */),
  "component---src-pages-work-punchy-mdx": () => import("./../../../src/pages/work/punchy.mdx" /* webpackChunkName: "component---src-pages-work-punchy-mdx" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

