import * as React from "react"
// import { base } from "../styles/ColorStyles"

export default function TestComponent({ children }) {
  return <div style={Styles}>{children}</div>
}

const Styles = {
  background: `#E8F0F2`,
  padding: `1.5rem`,
  borderRadius: `6px`,
  margin: `2.5rem 0`,
  maxWidth: `70ch`,
}
