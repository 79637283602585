import * as React from "react"
import { base } from "../styles/ColorStyles"
import styled from "styled-components"

export default function Info(props) {
  return (
    <div style={Styles}>
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: "3px" }}
        >
          <g clip-path="url(#clip0_1352_875)">
            <path
              d="M16.9083 6.975L16.075 5.53333C15.8417 5.13333 15.3333 5 14.9333 5.225L11.6667 7.11667V3.33333C11.6667 2.875 11.2917 2.5 10.8333 2.5H9.16668C8.70835 2.5 8.33335 2.875 8.33335 3.33333V7.11667L5.05835 5.225C4.65835 4.99167 4.15001 5.13333 3.92501 5.525L3.09168 6.96667C2.85835 7.36667 2.99168 7.88333 3.39168 8.10833L6.66668 10L3.39168 11.8917C2.99168 12.125 2.85835 12.6333 3.08335 13.0333L3.91668 14.475C4.15001 14.875 4.65835 15.0083 5.05835 14.7833L8.33335 12.8833V16.6667C8.33335 17.125 8.70835 17.5 9.16668 17.5H10.8333C11.2917 17.5 11.6667 17.125 11.6667 16.6667V12.8833L14.9417 14.775C15.3417 15.0083 15.85 14.8667 16.0833 14.4667L16.9167 13.025C17.15 12.625 17.0083 12.1167 16.6083 11.8833L13.3333 10L16.6083 8.10833C17.0083 7.88333 17.1417 7.36667 16.9083 6.975Z"
              fill="grey"
            />
          </g>
          <defs>
            <clipPath id="clip0_1352_875">
              <rect width="20" height="20" fill="gray" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div>
        <Head>{props.header}</Head>
        <Body>{props.body}</Body>
      </div>
    </div>
  )
}

const Head = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  color: ${base.primarytext};

  @media (max-width: 650px) {
    font-size: 1.25rem;
  }
`
const Body = styled.p`
  font-size: 1rem;
  line-height: 1.4;
  margin: 0.25rem 0 0 0;

  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

const Styles = {
  background: `#e5e5e5`,
  padding: `1.5rem 1rem`,
  borderRadius: `4px`,
  margin: `2rem auto 2rem auto`,
  maxWidth: `944px`,
  display: `flex`,
  gap: `0.5rem`,
}
