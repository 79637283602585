import * as React from "react"

export default function embedVideo(props) {
  return (
    <div
      style={{
        maxWidth: "840px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "4px",
        marginTop: "2.5rem",
      }}
    >
      <video width="100%" controls autoPlay muted loop>
        <source src={props.src} type="video/mp4" />
      </video>
      <figcaption>{props.caption}</figcaption>
    </div>
  )
}
