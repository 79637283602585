export const base = {
  // blacks
  primarytext: `#0c0a09`,
  secondarytext: `#44403c`,
  tertiarytext: `#78716c`,
  white: `#FFF`,
  lightgray: `#E8F0F2`,
  border: `rgba(0,0,0, 0.12)`,
  hover: `rgba(37, 99, 235, 0.04)`,
  hoverneutral: `rgba(0, 0, 0, 0.02)`,
  


  // primarytext: `#d3d5da`,
  // secondarytext: `#cbd5e1`,
  // tertiarytext: `#94a3b8`,
  // white: `#FFF`,
  // lightgray: `#E8F0F2`,
  // border: `rgba(255,255,255, 0.1)`,
  // hover: `rgba(255, 255, 255, 0.04)`,

  //action
  actionactive: `#2563EB`,
  actionlight : `#2563EB`,
  actiondark: `#005CC5`,

  // actionactive: `#439dd6`,
  // actionlight : `#439dd6`,

  //greys
  background: `#F5F7F9`,
}
