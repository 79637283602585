import Seo from "../../../../src/components/seo";
import VideoPlayer from "../../../../src/components/post/video";
import BlockWrapper from "../../../../src/components/post/BlockWrapper";
import EmbedVideo from "../../../../src/components/post/embedvideo";
import * as React from 'react';
export default {
  Seo,
  VideoPlayer,
  BlockWrapper,
  EmbedVideo,
  React
};